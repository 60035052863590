<template>
  <div
    :class="['product-grid', `product-grid-layout-${layout}`]"
    itemscope
    itemtype="https://schema.org/ProductCollection"
  >
    <meta itemprop="url" :content="`${BASE_URL}${route?.fullPath}`" />
    <div v-if="headline || moreHref" class="product-grid-head">
      <Headline
        :id="headlineAnchor"
        :level="2"
        :size="3"
        class="product-list-headline"
      >
        {{ headline }}
      </Headline>
      <InternalLink v-if="moreLabel" :to="moreHref">
        {{ moreLabel }}
      </InternalLink>
    </div>
    <Paragraph v-if="subtext" class="product-list-paragraph" size="small">
      {{ subtext }}
    </Paragraph>

    <div
      class="products"
      itemprop="mainEntity"
      itemscope
      itemtype="https://schema.org/ItemList"
    >
      <template v-if="status === 'pending' && !products?.length">
        <div
          v-for="(_, index) in [
            ...Array(productAmountRouteQuery || CHUNK_SIZE),
          ]"
          :key="index"
          class="skeleton"
        />
      </template>
      <template v-for="product in products" v-else>
        <ProductThumb
          v-if="taxonId"
          :id="product.id"
          :key="product.id"
          :taxons="product.taxons"
          :src="product.imageUrl"
          :alt="product.imageAlt"
          :srcset="imageSrcset(product.imageSrcset, { maxWidth: 500 })"
          :name="product.name"
          :is-clean-scent="product.fragrance && product.fragrance.cleanScent"
          :ga-category="product.gaCategory"
          :rating="product.stars"
          :price-range="product.priceRange"
          :href="product.urlPath"
          :layout="layout === 'condensed' ? 'small' : 'default'"
          :is-new="product.newProduct"
          :is-discontinued="product.discontinued"
          :marketing-taxons="product.marketingTaxons"
          :review-count="product.reviewCount"
          class="product"
          sizes="(min-width: 1344px) 193px, (min-width: 1264px) calc(16vw - 31px),
          (min-width: 1060px) calc(20vw - 32px), (min-width: 856px) calc(25vw - 34px),
          (min-width: 660px) calc(33vw - 38px), (min-width: 604px) calc(33vw - 22px),
          (min-width: 357px) calc(50vw - 24px), calc(100vw - 32px)"
          itemprop="itemListElement"
        />
      </template>
    </div>
    <div v-if="showButton" class="load-more-wrap">
      <Button
        :loading="status === 'pending'"
        color="gray"
        @click="refreshProducts"
      >
        Show More
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { deserialize } from "@alchemy_cms/json_api"
  import { imageSrcset } from "~/utils/methods"

  const config = useRuntimeConfig()
  const BASE_URL = config.BASE_URL
  const router = useRouter()
  const route = useRoute()
  const CHUNK_SIZE = 30
  const { api } = useApi()
  const page = ref(1)

  const showButton = ref(true)

  const props = withDefaults(
    defineProps<{
      taxonId: number
      headline?: string
      headlineAnchor?: string
      subtext?: string
      moreLabel?: string
      moreHref?: string
      onlyLiveProducts?: boolean
      elementId: string
      elementName: string
      layout?: "default" | "condensed"
      sort?: "classifications_position" | "name"
    }>(),
    {
      headline: undefined,
      headlineAnchor: undefined,
      subtext: undefined,
      moreLabel: undefined,
      moreHref: undefined,
      onlyLiveProducts: true,
      layout: "default",
      sort: "classifications_position",
    },
  )

  const productAmountRouteQuery = computed(() => {
    const queryValue = Array.isArray(
      router.currentRoute.value.query["product-count"],
    )
      ? router.currentRoute.value.query["product-count"][0] || "0"
      : router.currentRoute.value.query["product-count"] || "0"
    const queryValueInt = parseInt(queryValue)

    if (queryValueInt % 30 !== 0) {
      return 0
    } else {
      return queryValueInt
    }
  })

  watch(
    () => route.query,
    () => {
      refresh()
    },
  )

  const {
    data: products,
    status,
    refresh,
  } = await useLazyAsyncData<SolidusProductThumb[]>(
    `${props.elementName}-${props.elementId}`,

    () =>
      api(`/jsonapi/taxons/${props.taxonId}/product_thumbnails`, {
        params: {
          include: "fragrance,taxons",
          fields: {
            product:
              "name,imageUrl,discontinued,imageSrcset,stars,priceRange,taxons,urlPath,marketingTaxons,fragrance,reviewCount",
          },
          filter: {
            live: props.onlyLiveProducts,
          },
          page: {
            number: 1,
            size: productAmountRouteQuery.value || CHUNK_SIZE,
          },
          sort: props.sort,
        },
      }),
    {
      transform(data: any) {
        showButton.value = page.value < data.meta?.pagination?.last
        return deserialize(data)
      },
      deep: false,
    },
  )

  const refreshProducts = () => {
    router.push({
      query: {
        "product-count": route.query["product-count"]
          ? productAmountRouteQuery.value + CHUNK_SIZE
          : CHUNK_SIZE * 2,
      },
    })
  }
</script>

<style lang="scss" scoped>
  @keyframes load {
    50% {
      opacity: 0.7;
    }
  }

  .product-grid {
    scroll-margin: $space-l * 3;
  }

  .product-grid-head {
    display: flex;
    align-items: baseline;
  }
  .product-list-headline {
    margin-right: $base-spacing * 4;
  }

  .products {
    display: grid;
    grid-gap: $base-spacing * 6;
    align-items: stretch;
    .product-grid-layout-default & {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      @include viewport("lg") {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      }
    }
    .product-grid-layout-condensed & {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
    @include viewport("mini") {
      grid-gap: $base-spacing * 4;
    }
  }

  .product-list-paragraph {
    color: $gray-text;
    margin-bottom: $space-m;
  }

  .skeleton {
    height: 273px;
    background: $gray-200;
    border-radius: $border-radius;
    animation: load 1.5s ease-in-out infinite;
  }

  .load-more-wrap {
    margin: $space-m 0;
    display: flex;
    align-content: center;
    justify-content: center;
  }
</style>
